<template>
  <div class='box'>
    <YzgHeader></YzgHeader>
    <div class="instuctions-header">
      <div class="content">
        <div class="top-box">
          <span class="instuctions-title">{{userNumber}}+民宿都在用云掌柜</span>
          <div class="focuson">
            专注于服务好每一位掌柜，持续给用户提供长期、稳定的服务，让天下没有难做的民宿生意。
          </div>
          <p class="nineshopkeeper">营销学不会 快用云掌柜</p>
        </div>
        <user-demo-tab-bar-item></user-demo-tab-bar-item>
      </div>
    </div>
    <user-demo-tab-bar-content></user-demo-tab-bar-content>
    <YzgFooter></YzgFooter>
  </div>
</template>

<script>
import YzgHeader from "@/components/YzgHeader"
import YzgFooter from "@/components/YzgFooter"
import userDemoTabBarContent from "@/components/userdemotabbar/userDemoTabBarContent"
import userDemoTabBarItem from "@/components/userdemotabbar/userDemoTabBarItem"
import axios from "axios"
export default {
  name: 'username',
  components: {
    YzgHeader,
    YzgFooter,
    userDemoTabBarItem,
    userDemoTabBarContent
  },
  data () {
    return {
      userNumber: ""
    };
  },
  methods: {
    getUserNumber() {
      axios.get('https://api.yunzhanggui.net/api/v1/inn/get_hotel_total?version=9.4.6.0')
      .then((res) => {
        let number = Number(res.data.ret_data.total) / 10000
        this.userNumber = Math.floor(number) * 10000
        console.log(res.data.ret_data.total)
      })
      .catch(function (err) {
        return err
      })
    }
  },
  computed: {
    // videoFlag:{
    //   get(){
    //     return this.$route.query.title == '客户案例-大理云端连锁' ? false : true
    //   }
    // }
  },
  props: {},
  watch: {},
  created () {
    this.getUserNumber()
  },
  mounted () {
  },
  beforeCreate () {}, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='less' scoped>
/*@import url(); //引入公共css类*/
@import url('../assets/index.css');
@import url('../assets/mediaUser.css');
@media screen and (min-width: 768px) {
.red {
  color: red;
}
.focuson {
  width: 738px;
  height: 32px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  text-shadow: 0px 2px 8px rgba(0, 121, 107, 0.4);
  margin-top: 24px;
  margin-bottom: 48px;
}
.nineshopkeeper {
  height: 32px;
  font-size: 32px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ffffff;
  line-height: 32px;
  text-shadow: 0px 2px 8px rgba(0, 121, 107, 0.4);
  margin-bottom: 104px;
}
.bottom_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box .instuctions-header {
  margin-bottom: 56px;
}
}
</style>
