<template>
  <div class="bottom_box">
    <div v-if="screenSize" class="box_item_position">
        <div v-for="(value, index) of items" :key="index" @click="delivery(value.method)">
          <div class="mr48" @click="getCurrentItem(value.name)" :class="{active:currentItem === value.name}">{{value.name}}</div>
        </div>
    </div>
    <div v-if="!screenSize">
          <a-select default-value="所有案例" style="width: 120px" class="posFix">
            <a-select-option v-for="(value, index) of items" :key="index" @click="delivery(value.method)">
              {{value.name}}
            </a-select-option>
          </a-select>
    </div>
  </div>

</template>
<script>
import eventBus from "@/eventBus";
export default {
  name: "userDemoTabBarItem",
  data () {
    return {
      currentItem: '所有案例',
      items: [
        {
          name:'所有案例',
          method: 'showAll'
        },
        {
          name: '单体民宿',
          method: 'showMonomer'
        },
        {
          name: '连锁品牌',
          method: 'showChain'
        },
        {
          name: '民宿集群',
          method: 'showColony'
        },
        {
          name: '民宿综合体',
          method: 'showSynthesis'
        },
      ],
      screenSize: true,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  methods: {
    getCurrentItem(value){
        this.currentItem = value
    },
    delivery(m) {
      eventBus.$emit('val',m)
      // console.log(m)
    },
  },
  computed: {},
  props: {},
  watch: {
    windowWidth() {
      let _this = this;
      if (_this.windowWidth >= 800) {
        _this.screenSize = true;
      } else {
        _this.screenSize = false;
      }
    }
  },
  created () {},
  mounted () {
    let _this = this
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        _this.windowWidth = window.fullWidth;
      })()
    }
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='less' scoped>
@import url('../../assets/index.css');
@import url('../../assets/mediaUser.css');
@media screen and (min-width: 768px) {
  .bottom_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
  }
  .mr48 {
    margin-right: 48px;
    width: 90px;
    height: 24px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #595959;
    line-height: 24px;
    cursor: pointer;
  }
  .active {
    font-weight: 600;
    color: #00796b;

  }
  .bottom_box .box_item_position {
    display: flex;
    margin-top: 80px;
  }
}
</style>
