<template>
  <div class="instuctions-wrapper">
    <div v-for="(item, index) of lists"
         @click="goDetail(item.id, item.title)"
         :key="index"
         class="specificcases">
      <div :class="show_qr === index? 'caseWhite' : 'caseBgc'">
        <img v-if="contrary_show_qr"
             @mouseleave="mouseleave_qr"
             @mouseenter="mouseenter_qr(index)"
             class="caseBgc"
             :src="item.cover_image"
             alt="">
        <div class="logo_bg">
          <div class="logo_white_bg">
            <img v-if="contrary_show_qr"
                 @mouseleave="mouseleave_qr"
                 @mouseenter="mouseenter_qr(index)"
                 class="logosmall"
                 :src="item.logo_image"
                 alt="">
          </div>
        </div>

        <div v-if="show_qr == index"
             @mouseleave="mouseleave_qr"
             @mouseenter="mouseenter_qr(index)"
             class="caseWhite location">
          <img v-if="mobile_five()" class="qrCode"
               :src="item.hotel_qr_image"
               alt="">
        </div>

      </div>
      <div class="detailedcases">
        <div class="detailedcasesname">​{{ item.hotelname }}</div>
        <div class="occupancyrate">{{ item.title }}</div>
        <div class="BandB">
          <div v-for="(n, m) of item.individual_tag"
               :key="m"
               class="BandBText">{{ n }}</div>
        </div>
      </div>
    </div>
    <div>
      <button @click="showMore()"
              v-if="this.lists.length < this.total"
              class="seemore">
        <span class="seemoreText">查看更多</span>
      </button>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
import axios from 'axios'
export default {
  name: "userDemoTabBarContent",
  data(){
    return {
      methodName: 'showAll',
      lists: [],
      total: '',
      // 是否下拉
      isDown:true,
      isDownMonomer: false,
      // 显示所有
      chooseText: true,
      // 显示单体
      chooseMonomer: false,
      // 显示连锁
      chooseChain: false,
      // 显示集群
      chooseColony: false,
      // 显示综合体
      chooseSynthesis: false,
      // 显示二维码
      show_qr: -1,
      // 隐藏二维码
      contrary_show_qr: true,
      // 数据条数
      pageSize: 12,
      // 当前页数
      pageIndex: 1,
      pageSizeChain: 12,
      pageIndexChain: 1,
      pageSizeMonomer: 12,
      pageIndexMonomer: 1,
      pageSizeColony: 12,
      pageIndexColony: 1,
      pageSizesynthesis: 12,
      pageIndexsynthesis: 1,
      // 所有案例显示更多
      isAllshowmore: [],
      // 单体民宿更多
      ismonomeMore: [],
      // 连锁品牌更多
      ischainMore: [],
      // 民宿综合体
      issynthesisMore: [],
      // 民宿集群
      iscolonyMore: [],
      testEnv:"https://testm.miot.com.cn/",
      proEnv:"https://m.yunzhanggui.net/"
    }
  },
  methods: {
    // 所有案例
    showAll () {
      this.pageIndex = 1
      this.isAllshowmore = []
      this.chooseText = true
      this.chooseMonomer = false
      this.chooseChain = false
      this.chooseColony = false
      this.chooseSynthesis = false
      this.getAllHotelList()
    },
    // 单体民宿
    showMonomer () {
      this.pageIndexMonomer = 1
      this.ismonomeMore = []
      this.chooseMonomer = true
      this.chooseText = false
      this.chooseChain = false
      this.chooseColony = false
      this.chooseSynthesis = false
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSizeMonomer,
          pageIndex: this.pageIndexMonomer,
          tag: '单体民宿'
        }
      })
          .then((res) => {
            // let monomerViceList = res.data.filter(item => {
            //   return item.tag === '单体民宿'
            // })
            this.lists = res.data.list
            this.total = Number(res.data.total)

          })
          .catch(function () {
            return
          });
    },
    // 连锁品牌
    showChain () {
      this.pageIndexChain = 1
      this.ischainMore = []
      this.chooseChain = true
      this.chooseMonomer = false
      this.chooseText = false
      this.chooseColony = false
      this.chooseSynthesis = false
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSizeChain,
          pageIndex: this.pageIndexChain,
          tag: '连锁品牌'
        }
      })
          .then((res) => {
            // let chainViceList = res.data.filter(item => {
            //   return item.tag === '连锁品牌'
            this.total = Number(res.data.total)
            this.lists = res.data.list
          })
          .catch(function () {
            return
          });
    },
    // 民宿集体
    showColony () {
      this.pageIndexColony = 1
      this.iscolonyMore = []
      this.chooseColony = true
      this.chooseChain = false
      this.chooseMonomer = false
      this.chooseText = false
      this.chooseSynthesis = false
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSizeColony,
          pageIndex: this.pageIndexColony,
          tag: '民宿集群'
        }
      })
          .then((res) => {
            // let ColonyViceList = res.data.filter(item => {
            //   return item.tag === '民宿集群'
            // })
            this.total  = Number(res.data.total)
            this.lists = res.data.list
          })
          .catch(function () {
            return
          });
    },
    // 民宿综合体
    showSynthesis () {
      this.pageIndexsynthesis = 1
      this.issynthesisMore = []
      this.chooseSynthesis = true
      this.chooseColony = false
      this.chooseChain = false
      this.chooseMonomer = false
      this.chooseText = false
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSizesynthesis,
          pageIndex: this.pageIndexsynthesis,
          tag: '民宿综合体'
        }
      })
          .then((res) => {
            console.log('超超超超存储错错', res)
            // let synthesisViceList = res.data.filter(item => {
            //   return item.tag === '民宿综合体'
            // })
            this.total = Number(res.data.total)
            this.lists = res.data.list
          })
          .catch(function () {
            return
          });
    },
    // 获取所有酒店列表
    async getAllHotelList () {
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex
        }
      })
      .then((res) => {
        console.log(res, '所有酒店')
        this.lists = res.data.list
        this.total = Number(res.data.total)
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    // 显示二维码
    mouseenter_qr (e) {
      this.show_qr = e;
    },
    // 隐藏二维码
    mouseleave_qr () {
      this.show_qr = -1;
      this.contrary_show_qr = true

    },
    goDetail () {
      return;
    },
    // // 跳转到具体
    // goDetail (id, title) {
    //   return;
    //   // console.log(title, '对对对')
    //   let routeData = this.$router.resolve({
    //     path: '/detailCase',
    //     query: {
    //       id: id,
    //       title: title
    //     }
    //   })
    //   window.open(routeData.href, '_blank');
    // },

    // 查看更多所有案例
    seemore () {
      this.pageIndex += 1
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        }
      })
      .then((res) => {
        // console.log(res, '1111');
        this.isAllshowmore = res.data.list
        console.log(this.lists.length, '显示的数量')
        this.total = Number(res.data.total)
        console.log('执行了', this.total)
        this.lists = [...this.lists, ...res.data.list]
      })
      .catch(function () {
        return
      });
    },

    // 更多单体民宿
    monomerMore () {
      this.pageIndexMonomer += 1
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSizeMonomer,
          pageIndex: this.pageIndexMonomer,
          tag: '单体民宿'
        }
      })
      .then((res) => {
        this.lists = res.data.list
        console.log(res.data, '拜拜拜拜')
        this.total = Number(res.data.total)
        // let monomerViceList = res.data.filter(item => {
        //   return item.tag === '单体民宿'
        // })
        // this.monomerList = [...this.monomerList, ...monomerViceList]
        this.lists = [...this.lists, ...res.data.list]


      })
      .catch(function () {
        return
      });
    },

    // 更多连锁
    chainMore () {
      this.pageIndexChain += 1
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSizeChain,
          pageIndex: this.pageIndexChain,
          tag: '连锁品牌'
        }
      })
      .then((res) => {
        this.ischainMore = res.data.list
        // let chainViceList = res.data.filter(item => {
        //   return item.tag === '连锁品牌'
        // })
        this.total = Number(res.data.total)
        this.lists = [...this.lists, ...res.data.list]
      })
      .catch(function () {
        return
      });
    },

    // 更多民宿集群
    colonyMore () {
      this.pageIndexColony += 1
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSizeColony,
          pageIndex: this.pageIndexColony,
          tag: '民宿集群'
        }
      })
      .then((res) => {
        this.iscolonyMore = res.data.list
        // let colonyViceList = res.data.filter(item => {
        //   return item.tag === '民宿集群'
        // })
        this.total  = Number(res.data.total)
        this.lists = [...this.lists, ...res.data.list]
      })
      .catch(function () {
        return
      });
    },

    // 更多民宿综合体
    synthesisMore () {
      this.pageIndexsynthesis += 1
      axios.get(this.proEnv + '/apiv1/official_web_manage.php?action=getlist&key=miotoa', {
        params: {
          pageSize: this.pageSizesynthesis,
          pageIndex: this.pageIndexsynthesis,
          tag: '民宿综合体'
        }
      })
      .then((res) => {
        this.issynthesisMore = res.data.list
        // let synthesisViceList = res.data.filter(item => {
        //   return item.tag === '民宿综合体'
        // }
        this.total = Number(res.data.total)
        this.lists = [...this.lists, ...res.data.list]
      })
      .catch(function () {
        return
      });
    },
    // 获取屏幕宽度
    mobile_five() {
      return window.screen.width >= 1200;
    },
    chooseMethod(m){
      this[m]()
    },
    showMore(){
      if(this.methodName === 'showAll') {
        this.seemore()
      }
      if(this.methodName === 'showMonomer'){
        this.monomerMore()
      }
      if(this.methodName === 'showChain'){
        this.chainMore()
      }
      if(this.methodName === 'showColony'){
        this.colonyMore()
      }
      if(this.methodName === 'showSynthesis'){
        this.synthesisMore()
      }
    }
  },
  props: {},
  watch: {
    methodName: function () {
      let m = this.methodName
      this.chooseMethod(m)
    }
  },
  created () {
    // 获取所有酒店列表
    this.getAllHotelList()
  },
  mounted () {
    let _this = this
    eventBus.$on('val',function(m){
      _this.methodName = m
      // console.log(_this.methodName)
    })
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang="less" scoped>
/*@import url(); //引入公共css类*/
//@import url('../../assets/index.css');
//@import url('../../assets/mediaUser.css');
@media screen and (min-width: 768px) {
  .instuctions-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .specificcases {
    width: 288px;
    height: 320px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.06);
  }
  .specificcases:nth-child(3n + 1) {
    margin-left: 80px;
  }
  .specificcases:nth-child(3n - 1) {
    margin-left: 48px;
    margin-right: 48px;
  }
  .specificcases:nth-child(n + 4) {
    margin-top: 48px;
  }
  .caseBgc {
    width: 288px;
    height: 192px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px 8px 0 0;
    position: relative;
    cursor: pointer;
  }
  .seemore {
    width: 995px;
    height: 56px;
    background: #ffffff;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    margin-top: 80px;
    cursor: pointer;
    margin-left: 62px;
  }
  .detailedcases {
    width: 100%;
    height: 128px;
    margin-left: 24px;
    margin-top: 20px;
  }
  .occupancyrate {
    width: 240px;
    height: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    line-height: 16px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .BandB {
    /*width: 60px;*/
    /*height: 20px;*/
    /*background: linear-gradient(225deg, #fff1ea 0%, #d9fdf3 100%);*/
    /*border-radius: 2px;*/
    display: flex;
    text-align: center;
  }
  .BandBText {
    /*width: 48px;*/
    height: 20px;
    font-size: 12px;
    font-weight: 400;
    color: #00ac99;
    line-height: 20px;
    /*width: 60px;*/
    background: linear-gradient(225deg, #fff1ea 0%, #d9fdf3 100%);
    border-radius: 2px;
    margin-right: 5px;
  }
  .seemore {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .seemoreText {
    width: 75px;
    height: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #595959;
    line-height: 24px;
    cursor: pointer;
  }
  .qrCode {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 128px;
    height: 128px;
  }
  .caseWhite {
    position: relative;
    width: 288px;
    height: 192px;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    background: white !important;
  }
  .location {
    position: absolute;
    top: 0;
    left: 0;
  }
  .logo_bg{
    width: inherit;
    height: inherit;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px 8px 0 0;
    position: absolute;
    top: 0;
  }
  .logo_white_bg{
    width: 64px;
    height: 64px;
    background: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  .detailedcasesname {
    width: 240px;
    height: 24px;
    font-size: 18px;
    font-weight: 500;
    color: #262626;
    line-height: 24px;
  }
}

</style>
